.required::after{
    content: ' *';
    color: #ff0000;
}
.invalid-feedback{
    color: #ff0000;
    font-size:10px;
}
.form-control {
    font-size: 1.5rem !important;
}
.title--section{
    padding: 40px;
}
.cart-badge{
    border: 1px solid #777;
    padding: 5px 15px;
}

.cart-badge{
    background-color: #ddd;
    border: 1px solid #777;
    padding: 5px 15px;
}

#deliveryForm .form-control {
    font-size: 1.6rem;
}

.mt--10{
    margin-top: -10px;
}

.menu--block .inner-box .info{
    margin-bottom: 0px;
}
.cart-badge{
    color: #4d4d4d;
}
select#time_slot_id{
    height: 34px;
}

.cart--header {
    background-color: #ffffffbd;
    padding: 2px 8px;
    border-radius: 5px;
}
.cart--header .fa-shopping-cart{
    color: #000000;
    font-size: 16px;
}
.notification-counter{
    font-size: 13px;
}
.btn-style-one {
    padding: 12px 49px;
}
.btn-style-one--reverse{
    background: 0 0;
    color: #8b755a;
    border-color: #8b755a;
}
.btn-style-one--reverse:hover{
    color: #fff;
    background-color: #8b755a;
}
.btn--see--cart{
    background-color: #fff;
    color: #8b755a;
    border-color: #8b755a;
    margin:0;
    padding: 5px 15px;
}
.btn--see--cart:hover{
    color: #fff;
    background-color: #8b755a;
}
.title{
    line-height: 1em;
}

/* SLIDER RANGE */
.slider-handle.custom::before,
.slider-handle.custom::after{
    line-height: 20px;
    font-size: 20px;
    color: #726204;
    content: '' !important;
    width: 12px;
    height: 1px;
}
.slider-handle.custom::before{
    top: 37%;
}
.slider-handle.custom::after{
    top:55%;
}
.slider-handle.custom::before,
.slider-handle.custom::after{
    background: grey none;
    /* background-image: url(../img/range_slider.png); */
    background-size: contain;
    background-position-y: center;
    color: grey !important;
    left: 7px;
    position: absolute;
}
.slider-handle.custom{
    background-color: #dedede !important;
    width: 28px;
    height: 25px;
    border-radius: 4px;
    border: 1px solid grey;
    top: -2px;
}

.slider-selection{
    background-color:grey !important;
    border-radius:0px !important;
    background-image: none !important;
}
.slider-track{
    border-radius: 0px !important;
    border: 1px solid #9e9e9e;
    height: 12px !important;
}
.slider.slider-horizontal{
    width: 100% !important;
}
.slider--val{
    border: 2px solid grey;
    border-radius: 4px;
    padding: 3px 0px;
    font-weight: 900;
    color: #666363;
    width: 60%;
    display: inline-block;
    text-align: -webkit-center;
    margin-top: 12px;
}
.input-group{
    margin-bottom: 10px;
}
.title--range{
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    color: #666363;
    padding-bottom: 0px;
}
.range-slider{
    padding-top: 15px;
    padding-bottom: 15px;
}

/* BottomHeader */
.cartDiv {
    /*position: fixed;*/
    z-index: 9999;
    /*bottom: 0px;*/
    width: 100%;
    margin-bottom: 0px !important;
    background-color: #968065;
    padding: 10px;
}


.footer-style-two{
    z-index: 1;
}
.scroll-to-top{
    bottom: 60px;
}
.innerHeader {
    font-size: large;
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
}
.btn-outline-delivery,.btn-outline-takeaway {
    /*color: #F08505;
    background-color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 5px;
    border: 1px solid #F08505;
    padding: 5px 25px;*/
    padding: 5px 40px;
    margin: 0;
}

.cartDiv img{
    width: 30px;
}
.cartDiv span{
    color: #fff;
    font-weight: 600;
    font-size: 23px;
    vertical-align: middle;
}

/* Pagina Verifica Ordine */
.review--order--back {
    background-color: #f5f5f5;
    padding: 10px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}
.review--total {
    padding-top: 20px;
}
.review--total h3,
.review--total .price{
    background-color: #f5f5f5 !important;
    text-transform: uppercase;
}
.mt--3{
    margin-top: -3px;
}
.btn--concludi {
    background-color: #F08505;
    color: #fff;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 10px 40px;
    margin: 20px 0;
}
.menu-block .inner-box .info h3.totale {
    color: #6e6e6e;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 25px;
    margin-top: -15px;
    padding-bottom: 0;
    border-top: 0;
}
.iva{
    text-transform: uppercase;
    color: #808080;
}

.totale {
    border-top: 1px solid grey;
    padding: 10px 0;
    font-weight: 600;
}

.riepilogo--dati {
    background-color: #f5f5f5;
    padding: 10px;
    margin-bottom: 10px;
    font-weight: 600;
}

/* Takeaway */
.cartDiv.takeawayHeader {
    background-color: #968065;
}
/*
.btn-outline-takeaway{
    color: #E01967;
    border-color: #E01967;
}*/

.thanks--info{
    background-color: #f5f5f5;
    padding: 20px;
    margin-bottom: 0;
}


.back--news {
    padding: 55px 0px 50px;
    border-bottom: 1px solid #e5e5e5;
}
.timepicker {
    text-align: center !important;
}

/* INDEX NEWS */
.our-blog .pagination .page-item a,
.our-blog .pagination .page-item span{
    position: relative;
    display: inline-block;
    line-height: 40px;
    height: 44px;
    text-transform: capitalize;
    min-width: 40px;
    background: none;
    color: #232323;
    text-align: center;
    border: 2px solid transparent;
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    padding: 0 !important;
    font-weight: 400 !important;
    font-size: 10px !important;
}

.our-blog .pagination .page-item.active span{
    color: #232323;
    background: none;
    border-color: #232323;
}
.btn--paga{
    background-color: #14b14b !important;
}
@media only screen
and (min-device-width: 320px)
and (max-device-width: 812px) {
    .btn--paga{
        margin-top: 10px;
    }
}