/* Monde - Restaurant HTML Template */

@media only screen and (max-width: 1340px) {

    .page-wrapper {
        overflow: hidden;
    }

    .fullwidth-page .page-wrapper {
        padding: 15px 15px;
    }

}

@media only screen and (max-width: 1140px) {

    .main-header {
        position: relative;
        background-color: #222222;
    }

    .header-style-five {
        background-color: #ffffff;
    }

    .main-header.header-style-four {
        background-color: #ffffff;
    }

    .page-title {
        padding: 120px 0px;
    }

    .main-menu .navigation > li {
        margin-right: 20px;
    }

    .main-header .nav-outer .more-options {
        margin-top: 42px;
    }

    .header-style-three .header-upper .inner-container {
        padding: 0px;
    }

    .story-block .inner-box .content-column .inner-content {
        padding: 60px 40px 60px;
    }

    .carousel-section .owl-nav .owl-prev {
        left: 30px;
        color: #ffffff;
    }

    .carousel-section .owl-nav .owl-next {
        right: 30px;
        color: #ffffff;
    }

    .team-single-section .outer-container .content-column .content-inner:after {
        display: none;
    }

    .chef-block .inner-box .content-column .content-inner {
        padding: 100px 40px;
    }

    .event-block-two .inner-box .content-box .inner {
        padding: 50px 20px;
    }

    .story-block-two .inner-box h2 {
        font-size: 36px;
    }

    .contact-page-section .outer-container .form-column .inner-column {
        padding: 50px 30px;
    }

    .contact-page-section .outer-container {
        padding: 0px 15px;
    }

}

@media only screen and (max-width: 1023px) {

    .main-slider .tparrows.preview3.tp-rightarrow {
        right: -13px !important;
    }

    .main-menu .navigation > li.dropdown a {
        padding-right: 0px;
    }

    .sidebar-header .main-box {
        border-width: 100px 20px 70px 20px;
    }

    .fixed-header .sticky-header {
        display: none;
    }

    .main-header .header-upper .nav-outer {
        padding-right: 0px;
        width: 100%;
    }

    .main-slider h2 {
        font-size: 42px;
    }

    .main-header .nav-outer .more-options {
        margin-top: 29px;
        margin-bottom: 30px;
    }

    .main-menu .navigation > li {
        padding: 4px 0px 4px;
    }

    .main-header .logo-outer {
        padding: 15px 0px 5px;
    }

    .header-style-three .header-upper .inner-container {
        padding: 0px;
    }

    .main-header .nav-outer .more-options {
        display: none;
    }

    .main-menu .navigation > li {
        margin-right: 15px;
    }

    .main-menu .navigation > li > a {
        font-size: 10px;
    }

    .call-to-action,
    .call-to-action-three {
        padding: 150px 0px 140px;
    }

    .call-to-action h2 {
        font-size: 60px;
    }

    .main-footer {
        padding-bottom: 0px;
    }

    .fullwidth-services .outer-box .content-column .content-inner,
    .fullwidth-services-two .outer-box .content-column .content-inner,
    .team-single-section .outer-container .content-column .content-inner {
        max-width: 100%;
    }

    .fullwidth-services .outer-box .image-column,
    .fullwidth-services-two .outer-box .image-column,
    .team-single-section .outer-container .image-column {
        background: none !important;
    }

    .fullwidth-services .outer-box .image-column .hidden-image,
    .fullwidth-services-two .outer-box .image-column .hidden-image,
    .team-single-section .outer-container .image-column .image {
        display: block;
    }

    .fullwidth-services .outer-box .content-column,
    .fullwidth-services .outer-box .image-column,
    .fullwidth-services-two .outer-box .image-column,
    .fullwidth-services-two .outer-box .content-column,
    .team-single-section .outer-container .content-column,
    .team-single-section .outer-container .image-column {
        position: relative;
        width: 100%;
    }

    .fullwidth-services .outer-box .content-column .content-inner {
        margin-top: 0px;
    }

    .fullwidth-section {
        padding-bottom: 60px;
    }

    .testimonial-block-two .inner-box .text:before,
    .testimonial-block-two .inner-box .text:after,
    .reserve-form .text br,
    .error-section .inner-box h2:before,
    .error-section .inner-box h2:after,
    .coming-soon-section .inner-box h2:before,
    .coming-soon-section .inner-box h2:after {
        display: none;
    }

    .fullwidth-page .page-wrapper {
        padding: 0px 20px;
    }

    .fullwidth-page {
        padding: 15px;
    }

    .unique-section h3,
    .unique-section .fact-text {
        text-align: left;
    }

    .carousel-section .owl-nav {
        top: 35%;
    }

    .newsletter-widget .form-group input[type="text"],
    .newsletter-widget .form-group input[type="email"] {
        max-width: 100%;
    }

    .team-single-section .outer-container .content-column .content-inner {
        padding: 100px 40px;
    }

    .event-block-two .inner-box .content-box .inner {
        padding-left: 0px;
    }

    .event-block-two {
        margin-bottom: 0px;
    }

    .error-section,
    .coming-soon-section {
        padding: 180px 0px 150px;
    }

    .footer-style-two .footer-widget {
        min-height: 200px;
    }

}

@media only screen and (min-width: 992px) {

    .page-title h2 {
        display: none;
        color: #FFF;
        font-size: 35px;
    }
}

@media only screen and (max-width: 991px) {

    .table-section {
        position: relative;
        padding: 50px 0px 80px;
    }

    .page-title h2 {
        display: inline;
        color: #FFF;
        font-size: 35px;
    }

    .page-title {
        padding: 50px 0px !important;
    }
}

@media only screen and (min-width: 768px) {
    .main-menu .navigation > li > ul,
    .main-menu .navigation > li > ul > li > ul {
        display: block !important;
        visibility: hidden;
        opacity: 0;
    }
}

@media only screen and (max-width: 767px) {

    .main-header .main-menu {
        margin-top: -60px !important;
        width: 100%;
        margin: 0px;
    }

    .main-menu .collapse {
        max-height: 300px;
        overflow: auto;
        float: none;
        width: 100%;
        padding: 10px 0px 0px;
        border: none;
        margin: 0px;
        -ms-border-radius: 3px;
        -moz-border-radius: 3px;
        -webkit-border-radius: 3px;
        -o-border-radius: 3px;
        border-radius: 3px;
    }

    .main-menu .collapse.in,
    .main-menu .collapsing {
        padding: 10px 0px 0px;
        border: none;
        margin: 0px 0px 15px;
        -ms-border-radius: 3px;
        -moz-border-radius: 3px;
        -webkit-border-radius: 3px;
        -o-border-radius: 3px;
        border-radius: 3px;
    }

    .main-menu .navbar-header {
        position: relative;
        float: none;
        display: block;
        text-align: right;
        width: 100%;
        padding: 0px 0px 12px;
        right: 0px;
        z-index: 12;
    }

    .main-menu .navbar-header .navbar-toggle {
        display: inline-block;
        z-index: 7;
        border: 1px solid #8b755a;
        float: none;
        margin: 0px 0px 0px 0px;
        border-radius: 0px;
        background: #8b755a;
    }

    .main-menu .navbar-header .navbar-toggle .icon-bar {
        background: #ffffff;
    }

    .main-menu .navbar-collapse > .navigation {
        float: none !important;
        margin: 0px !important;
        width: 100% !important;
        background: #8b755a;
        border: 1px solid rgba(255, 255, 255, 0.50);
        border-top: none;
    }

    .main-menu .navbar-collapse > .navigation > li {
        margin: 0px !important;
        float: none !important;
        width: 100%;
        padding: 0px;
    }

    .main-menu .navigation > li > a,
    .main-menu .navigation > li > ul:before {
        border: none;
    }

    .main-menu .navigation li.dropdown > a:after,
    .main-menu .navigation > li.dropdown > a:before,
    .main-menu .navigation > li > ul > li > a::before,
    .main-menu .navigation > li > ul > li > ul > li > a::before {
        color: #ffffff !important;
        right: 15px;
        font-size: 16px;
        display: none !important;
    }

    .main-menu .navigation > li > ul > li > a {
        font-size: 11px;
    }

    .main-menu .navbar-collapse > .navigation > li > ul,
    .main-menu .navbar-collapse > .navigation > li > ul > li > ul {
        position: relative;
        border: none;
        float: none;
        visibility: visible;
        opacity: 1;
        display: none;
        margin: 0px;
        left: auto !important;
        right: auto !important;
        top: auto !important;
        width: 100%;
        background: #8b755a;
        -webkit-border-radius: 0px;
        -ms-border-radius: 0px;
        -o-border-radius: 0px;
        -moz-border-radius: 0px;
        border-radius: 0px;
        transition: none !important;
        -webkit-transition: none !important;
        -ms-transition: none !important;
        -o-transition: none !important;
        -moz-transition: none !important;
        -webkit-transform: scale(1) !important;
        -ms-transform: scale(1) !important;
        -o-transform: scale(1) !important;
        -moz-transform: scale(1) !important;
        transform: scale(1) !important;
        -webkit-box-shadow: none;
        -ms-box-shadow: none;
        -o-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    .main-menu .navbar-collapse > .navigation > li,
    .main-menu .navbar-collapse > .navigation > li > ul > li,
    .main-menu .navbar-collapse > .navigation > li > ul > li > ul > li {
        border-top: 1px solid rgba(255, 255, 255, 0.50) !important;
        opacity: 1 !important;
        top: 0px !important;
        left: 0px !important;
        visibility: visible !important;
    }

    .main-menu .navbar-collapse > .navigation > li:first-child {
        border: none;
    }

    .main-menu .navbar-collapse > .navigation > li > a,
    .main-menu .navbar-collapse > .navigation > li > ul > li > a,
    .main-menu .navbar-collapse > .navigation > li > ul > li > ul > li > a {
        padding: 10px 10px !important;
        line-height: 22px;
        color: #ffffff;
        background: #8b755a;
        text-align: left;
        min-height: 0px;
        font-weight: 500;
    }

    .main-menu .navbar-collapse > .navigation > li > a:hover,
    .main-menu .navbar-collapse > .navigation > li > a:active,
    .main-menu .navbar-collapse > .navigation > li > a:focus {
        background: #8b755a;
    }

    .main-menu .navbar-collapse > .navigation > li:hover > a,
    .main-menu .navbar-collapse > .navigation > li > ul > li:hover > a,
    .main-menu .navbar-collapse > .navigation > li > ul > li > ul > li:hover > a,
    .main-menu .navbar-collapse > .navigation > li.current > a,
    .main-menu .navbar-collapse > .navigation > li.current-menu-item > a,
    .header-style-two.inner-header .main-menu .navigation > li > a {
        background: #8b755a;
        color: #ffffff !important;
    }

    .main-menu .navigation li.dropdown .dropdown-btn {
        display: block;
    }

    .main-menu .navbar-collapse > .navigation li.dropdown:after,
    .main-menu .navigation > li > ul:before {
        display: none !important;
    }

    .main-header .nav-outer .dropdown-outer {
        left: 0px;
    }

    .main-header .nav-outer .dropdown-outer .dropdown-menu {
        top: 58px;
        left: 0px;
    }

    .main-slider h2 {
        font-size: 36px;
    }

    .main-header .nav-outer .more-options {
        display: none;
    }

    .main-header .header-upper .upper-right {
        width: 100%;
    }

    /*Responsive*/
    .collapse.in {
        display: block;
        transition: all 600ms ease;
        -webkit-transition: all 600ms ease;
        -ms-transition: all 600ms ease;
        -o-transition: all 600ms ease;
        -moz-transition: all 600ms ease;
    }

    .collapse {
        display: none;
        transition: all 600ms ease;
        -webkit-transition: all 600ms ease;
        -ms-transition: all 600ms ease;
        -o-transition: all 600ms ease;
        -moz-transition: all 600ms ease;
    }

    .navbar-collapse {
        padding-right: 15px;
        padding-left: 15px;
        overflow-x: visible;
        -webkit-overflow-scrolling: touch;
        border-top: 1px solid transparent;
        -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1);
        box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1);
    }

    .navbar-toggle {
        position: relative;
        float: right;
        padding: 9px 10px;
        margin-top: 8px;
        margin-right: 15px;
        margin-bottom: 8px;
        background-color: transparent;
        background-image: none;
        border: 1px solid transparent;
        border-radius: 4px;
    }

    .navbar-toggle .icon-bar + .icon-bar {
        margin-top: 4px;
    }

    .navbar-toggle .icon-bar {
        display: block;
        width: 22px;
        height: 2px;
        border-radius: 1px;
    }

    .navbar-collapse.in {
        overflow-y: auto;
        transition: all 500ms ease;
        -moz-transition: all 500ms ease;
        -webkit-transition: all 500ms ease;
        -ms-transition: all 500ms ease;
        -o-transition: all 500ms ease;
    }

    .main-slider h3,
    .order-section h2,
    .call-to-action-three h2 {
        font-size: 60px;
    }

    .call-to-action h2:before,
    .call-to-action h2:after,
    .page-title h2:before,
    .page-title h2:after,
    .call-to-action-two .text br,
    .our-info-detail .content-column .inner-content .text br {
        display: none;
    }

    .fullwidth-services .outer-box .content-column .content-inner,
    .fullwidth-services-two .outer-box .content-column .content-inner {
        padding: 40px 15px 40px;
    }

    .call-to-action-two h2 {
        font-size: 40px;
    }

    .header-style-two .logo-outer {
        position: relative;
        left: auto;
        margin: 0px;
        z-index: 13;
    }

    .stories-section .inner-container .stories-blocks {
        padding: 30px 30px;
    }

    .carousel-section .owl-nav .owl-next,
    .carousel-section .owl-nav .owl-prev {
        font-size: 60px;
    }

    .carousel-section .owl-nav {
        top: 38%;
    }

    .place-section .content-column .content-inner {
        padding: 35px 35px;
    }

    .page-title h2 {
        font-size: 50px;
    }

    .chefs-section .inner-chef-container {
        padding: 40px 25px;
    }

    .event-block-two .inner-box .content-box .inner h3 {
        font-size: 20px;
    }

    .phone-reservation-section .reserve .content {
        left: 15px;
        bottom: 15px;
    }

    .phone-reservation-section .reserve .content .text {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .story-block-two .inner-box h2 {
        font-size: 26px;
    }

    .prod-tabs .reviews-container .rev-thumb {
        position: relative;
    }

    .prod-tabs .reviews-container .review-box {
        padding-left: 0px;
    }

    .contact-page-section .outer-container .form-column .inner-column {
        margin: 0px;
    }

    .contact-page-section .outer-container .map-column,
    .contact-page-section .outer-container .form-column {
        position: relative;
        width: 100%;
    }

}

@media only screen and (max-width: 599px) {

    .main-slider h2 {
        font-size: 22px;
    }

    .main-slider h3,
    .sec-title h2 {
        font-size: 36px;
    }

    .main-slider .styled-heading {
        font-size: 80px;
    }

    .main-slider .mouse-btn-down {
        display: none;
    }

    .call-to-action h2,
    .order-section h2 {
        font-size: 40px;
    }

    .page-title h2 {
        font-size: 32px;
    }

    .testimonial-block-two .inner-box .text {
        font-size: 16px;
    }

    .story-block .inner-box .content-column .inner-content {
        padding: 30px 15px 30px;
    }

    .story-block .inner-box .content-column .inner-content h3 {
        font-size: 20px;
    }

    .phone-reservation-section .reserve .content .text {
        display: none;
    }

    .error-section .inner-box h2 {
        font-size: 70px;
    }

    .coming-soon-section .inner-box h2 {
        font-size: 40px;
    }

    .prod-tabs .tab-btns .tab-btn {
        font-size: 14px;
    }

    .product-details .basic-details .details-header h4,
    .news-block-two .inner-box .lower-box h3 {
        font-size: 20px;
    }

}

@media only screen and (max-width: 489px) {

    .main-slider h2 {
        font-size: 16px;
    }

    .carousel-section .owl-nav .owl-next,
    .carousel-section .owl-nav .owl-prev {
        font-size: 40px;
    }

}
